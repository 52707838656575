import { FC } from 'react'

import { DefaultUneditable, FormItem } from '../components/Form/FormItem'
import { LOCALE } from '../config/locale'
import { VIEW_DATE_FORMAT } from '../constants'
import { RUS_DATETIME_FORMAT, toDate, toDateTime } from '../utils'
import { DateItem } from './DateItem'
import { DateItemProps } from './type'

const WrappedDateItem: FC<DateItemProps> = ({
    label,
    disabled,
    prefix,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyFutureAndToday,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
    disableTime = false,
    getPopupContainer,
    showTime,
    ...rest
}) => {
    const renderUneditable = (value: any) => {
        const valueForRender = disableTime && !showTime ? toDate(value) : toDateTime(value)
        return DefaultUneditable(prefix, value ? valueForRender : undefined)
    }

    return (
        <FormItem renderUneditable={renderUneditable} {...rest} label={label}>
            <DateItem
                onlyWorkDays={onlyWorkDays}
                onlyPast={onlyPast}
                onlyFuture={onlyFuture}
                onlyFutureAndToday={onlyFutureAndToday}
                onlyBeforeDate={onlyBeforeDate}
                onlyBeforeDateAndToday={onlyBeforeDateAndToday}
                locale={LOCALE}
                disabled={disabled}
                showTime={showTime}
                placeholder={showTime ? 'дд.мм.гггг чч:мм' : 'дд.мм.гггг'}
                format={showTime ? RUS_DATETIME_FORMAT : VIEW_DATE_FORMAT}
                getPopupContainer={getPopupContainer}
                {...rest}
            />
        </FormItem>
    )
}

export default WrappedDateItem
