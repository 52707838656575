import { DatePicker } from 'antd'
import { FC } from 'react'

import { DefaultUneditable, FormItem, FormItemProps } from '../components/Form/FormItem'
import { LOCALE } from '../config/locale'
import { VIEW_DATE_FORMAT } from '../constants'
import { Moment } from '../utils'
import { datePeriodFilter } from '../utils/datePickerHelper'
import { DateRangeItemProps, PLACEHOLDER } from './type'

const WrappedDateRangeItem: FC<DateRangeItemProps> = ({
    disabled,
    prefix,
    // getPopupContainer = () => document.querySelector(MAIN_SELECTOR) as HTMLElement,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
    onDatePickerChange,
    onOk,
    ...restProps
}) => {
    const filter = datePeriodFilter({
        onlyWorkDays,
        onlyPast,
        onlyFuture,
        onlyBeforeDate,
        onlyBeforeDateAndToday,
    })

    const renderUneditable = (value?: Moment[]) => {
        const value_1 = value?.[0]?.format(VIEW_DATE_FORMAT)
        const value_2 = value?.[1]?.format(VIEW_DATE_FORMAT)
        return (
            <>
                {DefaultUneditable(prefix, value_1)}&ensp;—&ensp;
                {DefaultUneditable(prefix, value_2)}
            </>
        )
    }

    return (
        <FormItem
            renderUneditable={renderUneditable as FormItemProps['renderUneditable']}
            {...restProps}
        >
            <DatePicker.RangePicker
                disabled={disabled}
                disabledDate={filter}
                placeholder={PLACEHOLDER}
                format="DD.MM.YYYY"
                locale={LOCALE}
                showTime
                onChange={onDatePickerChange}
                defaultValue={restProps.defaultValue}
                onOk={onOk}
                //getPopupContainer={getPopupContainer}
            />
        </FormItem>
    )
}

export default WrappedDateRangeItem
