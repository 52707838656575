import { FC, useCallback, useState } from "react";
import cx from 'classnames'
import { TDropdowItem } from "./types";
import { CheckIcon } from "../Icons";

import styles from './DropdownSelectorCell.m.less'


export interface IDropdownMenuProps {
    items: TDropdowItem[]
    onItemClick: (item: TDropdowItem) => void
    defaultItemKey?: string
}

export const DropdownMenu: FC<IDropdownMenuProps> = ({ items, onItemClick, defaultItemKey }) => {

    const [selected, setSelected] = useState<string | undefined>(defaultItemKey)

    const onItemClickHandler = useCallback((item: TDropdowItem) => {
        setSelected(item.key)
        onItemClick(item)
    }, [selected, onItemClick])

    const itemsEl = items.map(item => (
        <div
            className={cx(styles.dropdownMenuItem, { [styles.selected]: selected === item.key })}
            key={item.key}
            onClick={() => onItemClickHandler(item)}>
            <span className={styles.label}>{item.label ?? ''}</span>
            {selected === item.key && <CheckIcon className={styles.icon} />}
        </div>))

    return (
        <div className={styles.dropdownContainer}>
            {itemsEl}
        </div>
    )

}