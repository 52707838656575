
import { Dropdown } from "antd"
import { FC, useCallback, useEffect, useState } from "react"
import { DropdownMenu, IDropdownMenuProps } from "./DropdownMenu"

import styles from './DropdownSelectorCell.m.less'
import { TDropdowItem } from "./types"

export interface IDropdownSelectorCellProps extends IDropdownMenuProps {
    isChangingLabel: boolean
    defaultTitle?: string
}

export const DropdownSelectorCell: FC<IDropdownSelectorCellProps> = ({ items, onItemClick, defaultItemKey, defaultTitle = '', isChangingLabel = false }) => {
    const [title, setTitle] = useState(defaultTitle)

    const [isDropdownShown, setIsDropdownShown] = useState(false)

    const onItemClickHandler = useCallback((item: TDropdowItem) => {
        onItemClick(item)

        if (isChangingLabel && item.title) {
            setTitle(item.title)
        }

    }, [isChangingLabel])

    useEffect(() => {
        if (defaultItemKey && isChangingLabel) {
            const selectedDefaultItem = items.find(item => item.key === defaultItemKey)
            if (selectedDefaultItem && selectedDefaultItem.title) {
                setTitle(selectedDefaultItem.title)
            }
        }

    }, [defaultItemKey, items, isChangingLabel])

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
                onVisibleChange={setIsDropdownShown}
                visible={isDropdownShown}
                overlay={
                    <DropdownMenu items={items} onItemClick={onItemClickHandler} defaultItemKey={defaultItemKey} />
                }
                placement="bottomLeft"
                trigger={['click']}
            >
                <div className={styles.container}>
                    <span className={styles.containerLabel}>{title}</span>
                </div>
            </Dropdown>
        </div>
    )
}